import React, { useState } from "react"
import "./App.css"
import useAuth from "./hooks/useAuth"
import { useWeb3React } from "@web3-react/core"
import Pool from "./components/Pool/Pool"
import useEagerConnect from "hooks/useEagerConnect"
import { ConnectorNames } from "utils/web3React"

const App = () => {
  useEagerConnect()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const [showWallets, setShowWallets] = useState(false)
  return (
    <div className="app_app">
      <header>
        {!account ? (
          <div className="connect_app">
            <button className="button_app"
              onClick={() => {
                setShowWallets(true)
              }}
            >
              Connect
            </button>
            {showWallets && (
              <div>
                <button className="button_app"
                  onClick={() => {
                    login(ConnectorNames.Injected)
                  }}
                >
                  Metamask
                </button>
                <button className="button_app"
                  onClick={() => {
                    login(ConnectorNames.WalletConnect)
                  }}
                >
                  WalletConnect
                </button>
                <button className="button_app"
                  onClick={() => {
                    login(ConnectorNames.Injected)
                  }}
                >
                  Trust Wallet
                </button>
                <button className="button_app"
                  onClick={() => {
                    login(ConnectorNames.BSC)
                  }}
                >
                  Binance Chain Wallet
                </button>
              </div>
            )}
          </div>
        ) : (
          <button className="button_app" onClick={logout}>Disconnect</button>
        )}
      </header>
      {account ? <Pool /> : "Please connect Wallet"}
    </div>
  )
}

export default App
