import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"

import poolReducer from "./Pool"

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    pool: poolReducer,
  },
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
