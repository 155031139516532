/* eslint-disable import/no-unresolved */
import { useEffect } from "react"
import { useWeb3React } from "@web3-react/core"
import { useSelector } from "react-redux"
import { useAppDispatch } from "./index"
import { State } from "./types"
import { fetchUserData } from "./Pool/helpers"

export const useStaking = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Fetch pool details for user
    if (account) {
      dispatch(fetchUserData({ account }))
    }
  }, [account, dispatch])
}

export const usePoolUserData = () => {
  const userData = useSelector((state: State) => state.pool)
  return userData
}
