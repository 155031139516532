import address from "../config/constant/contracts"

export const getCakeAddress = () => {
  return address.cake
}

export const getMulticallAddress = () => {
  return address.multiCall
}

export const getStakingAddress = () => {
  return address.staking
}
