import { useWeb3React } from "@web3-react/core"
import React from "react"
import styled from "styled-components"
import { useCakeApprove } from "../../hooks/useApprove"
import { useDeposit } from "../../hooks/useDeposit"
import { useWithdraw } from "../../hooks/useWithdraw"
import { useAppDispatch } from "../../state"
import { usePoolUserData, useStaking } from "../../state/hooks"
import { fetchUserData } from "../../state/Pool/helpers"
import {
  getBalanceNumber,
  getFullDisplayBalance,
} from "../../utils/formatBalance"

const StyledInputContainer = styled.div`
  padding: 5px;
  display: flex;
  justify-content: left;
  border: 2px solid #FF2882;
  border-radius: 10px;
  background-color: #fff;
  font-family: "cronos-pro", Sans-Serif;
  min-width: 200px;
  margin: 5px 0px;
`

const Pool = () => {
  useStaking()
  const { account } = useWeb3React()
  const { allowance, loading, userBalance, pendingReward, cakeBalance } =
    usePoolUserData()
  const dispatch = useAppDispatch()
  const { onApprove } = useCakeApprove()
  const { onDeposit, onClaim } = useDeposit()
  const { onWithdraw } = useWithdraw()

  const [depositAmount, setDepositAmount] = React.useState("")
  const [withdrawAmount, setWithdrawAmount] = React.useState("")

  function handleDepositChange(event) {
    var regex = /^\d+\.?\d{0,18}$/
    if (regex.test(event.target.value)) {
      setDepositAmount(event.target.value)
    }
  }

  function handleWithdrawChange(event) {
    var regex = /^\d+\.?\d{0,18}$/
    if (regex.test(event.target.value)) {
      setWithdrawAmount(event.target.value)
    }
  }

  const handleApproveClick = async () => {
    await onApprove()
    dispatch(fetchUserData({ account }))
  }

  const handleMaxDeposit = () => {
    setDepositAmount(getFullDisplayBalance(cakeBalance))
  }

  const handleMaxWithdraw = () => {
    setWithdrawAmount(getFullDisplayBalance(userBalance))
  }

  const handleDepositClick = async () => {
    await onDeposit(depositAmount)
    dispatch(fetchUserData({ account }))
  }
  
  const handleWithdrawClick = async () => {
    await onWithdraw(withdrawAmount)
    dispatch(fetchUserData({ account }))
  }

  return allowance === "0" ? (
    <div>
      <button className="button_app" onClick={handleApproveClick}>Approve</button>
      <div>Please approve Cake</div>
    </div>
  ) : (
    <div className="app_app">
      <div>Wallet balance: {getBalanceNumber(cakeBalance)}&nbsp;$Cake</div>
      <div>Staked amount: {getBalanceNumber(userBalance)}&nbsp;$Cake</div>
      <div className="wrapper_dw">
        <StyledInputContainer>
          <input className="input_app" value={depositAmount} onChange={handleDepositChange} />
          <button className="button_max" onClick={handleMaxDeposit}>Max</button>
        </StyledInputContainer>
        <button className="button_app deposit">Deposit</button>
      </div>
      <div className="wrapper_dw">
        <StyledInputContainer>
          <input className ="input_app" value={withdrawAmount} onChange={handleWithdrawChange} />
          <button className="button_max" onClick={handleMaxWithdraw}>Max</button>
        </StyledInputContainer>
        <button className="button_app" onClick={handleWithdrawClick}>Withdraw</button>
      </div>
      <div>Your next payout balance: <strong>~{getBalanceNumber(pendingReward)}</strong>&nbsp;$MetaBabyCake</div>
      <button className="button_app" onClick={onClaim}>Claim Rewards</button>
    </div>
  )
}

export default Pool
