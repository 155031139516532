import { useWeb3React } from "@web3-react/core"
import { useCallback } from "react"
import { withdraw } from "../utils/callHelpers"
import { useStaking } from "./useContract"

export const useWithdraw = () => {
  const stakingContract = useStaking()
  const { account } = useWeb3React()

  const handleWithdraw = useCallback(
    async (amount) => {
      try {
        const tx = await withdraw(stakingContract, amount, account)
        return tx
      } catch (e) {
        return false
      }
    },
    [account, stakingContract]
  )

  return { onWithdraw: handleWithdraw }
}
