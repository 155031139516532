/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"
import { PoolState } from "../types"
import { fetchUserData } from "./helpers"

const initialState: PoolState = {
  allowance: "0",
  userBalance: "0",
  pendingReward: "0",
  cakeBalance: "0",
  loading: true,
}

export const pool = createSlice({
  name: "pool",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update user data with live data
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      const { allowance, userBalance, pendingReward, cakeBalance } =
        action.payload
      state.allowance = allowance
      state.userBalance = userBalance
      state.pendingReward = pendingReward
      state.cakeBalance = cakeBalance
      state.loading = false
    })
  },
})

// Actions
export default pool.reducer
