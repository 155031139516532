import { useWeb3React } from "@web3-react/core"
import { useCallback } from "react"
import { deposit } from "../utils/callHelpers"
import { useStaking } from "./useContract"

export const useDeposit = () => {
  const stakingContract = useStaking()
  const { account } = useWeb3React()

  const handleDeposit = useCallback(
    async (amount) => {
      try {
        const tx = await deposit(stakingContract, amount, account)
        return tx
      } catch (e) {
        return false
      }
    },
    [account, stakingContract]
  )

  const handleClaim = useCallback(async () => {
    try {
      const tx = await deposit(stakingContract, 0, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, stakingContract])

  return { onDeposit: handleDeposit, onClaim: handleClaim }
}
