import { getCakeAddress, getStakingAddress } from "../utils/addressHelpers"
import { getContract } from "../utils/contractHelpers"
import useWeb3 from "./useWeb3"
import StakingABI from "../config/abi/Staking.json"
import CakeABI from "../config/abi/Cake.json"
import { useMemo } from "react"

export const useStaking = () => {
  const web3 = useWeb3()
  return useMemo(
    () => getContract(StakingABI, getStakingAddress(), web3),
    [web3]
  )
}

export const useCake = () => {
  const web3 = useWeb3()
  return useMemo(() => getContract(CakeABI, getCakeAddress(), web3), [web3])
}
