import BigNumber from "bignumber.js"
import { ethers } from "ethers"

export const DEFAULT_TOKEN_DECIMAL = new BigNumber(10).pow(18)

export const approve = async (contract, address, account) => {
  return contract.methods
    .approve(address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const deposit = async (contract, amount, account) => {
  return contract.methods
    .stake(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account })
}

export const withdraw = async (contract, amount, account) => {
  return contract.methods
    .unstake(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account })
}
