import { useWeb3React } from "@web3-react/core"
import { useCallback } from "react"
import { getStakingAddress } from "../utils/addressHelpers"
import { approve } from "../utils/callHelpers"
import { useCake } from "./useContract"

export const useCakeApprove = () => {
  const cakeContract = useCake()
  const { account } = useWeb3React()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(cakeContract, getStakingAddress(), account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, cakeContract])

  return { onApprove: handleApprove }
}
