import { createAsyncThunk } from "@reduxjs/toolkit"
import CakeABI from "../../config/abi/Cake.json"
import StakingABI from "../../config/abi/Staking.json"
import multicall from "../../utils/multicall"
import { getStakingAddress, getCakeAddress } from "../../utils/addressHelpers"

export const fetchUserData = createAsyncThunk<
  {
    allowance: string
    userBalance: string
    pendingReward: string
    cakeBalance: string
  },
  { account: string }
>("pool/fetchUserData", async ({ account }) => {
  const stakingAddress = getStakingAddress()
  const cakeAddress = getCakeAddress()
  const allowanceCalls = [
    {
      address: cakeAddress,
      name: "allowance",
      params: [account, stakingAddress],
    },
    {
      address: cakeAddress,
      name: "balanceOf",
      params: [account],
    },
  ]
  const allowance = await multicall(CakeABI, allowanceCalls)

  const balanceCalls = [
    {
      address: stakingAddress,
      name: "stakes",
      params: [account],
    },
    {
      address: stakingAddress,
      name: "getPendingMycoinGain",
      params: [account],
    },
  ]
  const balance = await multicall(StakingABI, balanceCalls)

  return {
    allowance: allowance[0].toString(),
    userBalance: balance[0].toString(),
    pendingReward: balance[1].toString(),
    cakeBalance: allowance[1].toString(),
  }
})
