import BigNumber from "bignumber.js"
import { DEFAULT_TOKEN_DECIMAL } from "./callHelpers"

export const getBalanceNumber = (amount: string) => {
  return new BigNumber(amount).div(DEFAULT_TOKEN_DECIMAL).toFixed(3)
}

export const getFullDisplayBalance = (amount: string) => {
  return new BigNumber(amount).div(DEFAULT_TOKEN_DECIMAL).toFixed()
}
